<template>
  <v-container fluid>
    <Header headerClass="qualita-aria" title="Qualità dell'aria" />
    <v-container page>
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Inquinanti</h2>
          <v-card class="card-cont-img-behind-right pm-primario" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h3>PM Particolato sospeso</h3>
                <p class="mt-40">
                  Miscela di particelle solide e liquide sospese nell'aria. Ha
                  effetti sulla salute, ambiente, monumenti, edifici e
                  agricoltura. <br />
                </p>
                <p class="mt-40">
                  Il <strong>PM₁₀</strong> si riferisce a particelle con un
                  diametro inferiore a 10 μm (1 micron = 1 μm = 1 millesimo di
                  millimetro). <br />
                  Il <strong>PM₂,₅</strong> si riferisce a particelle fini con
                  diametro inferiore a 2,5 micron.
                </p>
                <p class="mt-40">
                  Entrambi i tipi di particolato possono essere facilmente
                  inalati.<br />
                  Si suddividono in particolato sospeso primario e secondario.
                  <br />
                  Il <strong>primario</strong> è quello emesso direttamente
                  dalle fonti di inquinamento. <br />
                  Il <strong>secondario</strong> si forma nell'atmosfera da SO₂,
                  NOₓ, NH₃ e VOC. <br />
                  NH₃, SO₂ e NOₓ reagiscono nell'atmosfera per formare composti
                  che a loro volta creano nuove particelle nell'aria o si
                  condensano su particelle preesistenti per dare origine ad
                  aerosol inorganici. <br />
                  La combinazione di NH₃ e SO₂ forma Solfato di ammonio. <br />
                  La combinazione di NH₃ e NOₓ forma Nitrato di ammonio. <br />
                  Alcuni COV si ossidano per formare composti che danno origine
                  ad aerosol organici secondari.
                </p>
                <div class="inquinanti-fonti mt-40">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.arpa.piemonte.it/approfondimenti/temi-ambientali/aria/aria/cartella-qualita-inquinanti/pagina-qualita-inquinanti#Particolato%20sospeso "
                        title="Questo link si apre in una nuova scheda"
                        >ARPA - Inquinanti - Particolato sospeso
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.arpa.piemonte.it/approfondimenti/glossario/polveri-atmosferiche "
                        title="Questo link si apre in una nuova scheda"
                        >ARPA - Glossario - Polveri atmosferiche
                        <i
                          class="fas fa-external-link-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card elevation="10" class="card-limit mt-40">
            <v-card-text>
              <v-simple-table v-if="!isMobile()" class="limit-table">
                <thead>
                  <tr>
                    <td colspan="4">
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.pmIcon
                        </v-icon>
                        Particolato sospeso
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20" aria-hidden="true"
                        >$vuetify.icons.calDaily</v-icon
                      >
                      <strong>Limite giornaliero</strong>
                    </td>
                    <td>Inferiore a</td>
                    <td><span class="green--text">50</span></td>
                    <td>microgrammi/m3s</td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mr-20" aria-hidden="true"
                        >$vuetify.icons.calYearly</v-icon
                      >
                      <strong>Limite annuale</strong>
                    </td>
                    <td>
                      Sforamento del limite giornaliero per non più di<br />
                      Concentrazione media annua entro i
                    </td>
                    <td>
                      <span class="green--text">35</span><br />
                      <span class="green--text">40</span>
                    </td>
                    <td>giorni l'anno<br />microgrammi/m3</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table v-if="isMobile()" class="limit-table">
                <thead>
                  <tr>
                    <td>
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.pmIcon
                        </v-icon>
                        Particolato sospeso
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite giornaliero</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Inferiore a
                      <span class="green--text">50</span> microgrammi/m3s
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calYearly</v-icon>
                      <strong>Limite annuale</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Sforamento del limite giornaliero per non più di
                      <span class="green--text">35</span> giorni l'anno
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Concentrazione media annua entro i
                      <span class="green--text">35</span> microgrammi/m3
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card class="card-cont-img-behind-left azoto" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h3>NO<small>x</small> Ossidi di Azoto</h3>
                <p class="mt-40">
                  Famiglia di gas che include il monossido di azoto (NO) e il
                  biossido di azoto (NO₂). La foschia marrone/rossastra che a
                  volte si vede sulle città è composta principalmente da ossidi
                  di azoto.<br />
                  NOₓ viene emesso da veicoli, navi, centrali elettriche,
                  industria e abitazioni. <br />
                  NO₂ è normato dalla legislazione.
                </p>
                <div class="inquinanti-fonti mt-40">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.arpa.piemonte.it/approfondimenti/temi-ambientali/aria/aria/cartella-qualita-inquinanti/pagina-qualita-inquinanti#Ossidi%20di%20azoto"
                        title="Questo link si apre in una nuova scheda"
                        >ARPA - Inquinanti - Ossidi di azoto
                        <i class="fas fa-external-link-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.arpa.piemonte.it/approfondimenti/glossario/biossido-di-azoto"
                        title="Questo link si apre in una nuova scheda"
                        >ARPA - Glossario - Biossido di azoto
                        <i
                          class="fas fa-external-link-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card elevation="10" class="card-limit mt-40">
            <v-card-text>
              <v-simple-table v-if="!isMobile()" class="limit-table">
                <thead>
                  <tr>
                    <td colspan="4">
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.noIcon
                        </v-icon>
                        Biossido di azoto
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite giornaliero</strong>
                    </td>
                    <td>Inferiore a</td>
                    <td><span class="green--text">200/h</span></td>
                    <td>microgrammi/m3s</td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calYearly</v-icon>
                      <strong>Limite annuale</strong>
                    </td>
                    <td>
                      Sforamento del limite giornaliero per non più di<br />
                      Concentrazione media annua entro i
                    </td>
                    <td>
                      <span class="green--text">18</span><br />
                      <span class="green--text">40</span>
                    </td>
                    <td>giorni l'anno<br />microgrammi/m3</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table v-if="isMobile()" class="limit-table">
                <thead>
                  <tr>
                    <td>
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.noIcon
                        </v-icon>
                        Biossido di azoto
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite giornaliero</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Inferiore a
                      <span class="green--text">200/h</span> microgrammi/m3s
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calYearly</v-icon>
                      <strong>Limite annuale</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Sforamento del limite giornaliero per non più di
                      <span class="green--text">18</span> giorni l'anno
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Concentrazione media annua entro i
                      <span class="green--text">40</span> microgrammi/m3
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card class="card-cont-img-behind-right ozono" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h3>O<small>3</small> Ozono</h3>
                <p class="mt-40">
                  L'ozono è un inquinante "secondario" formato da gas come
                  ossidi di azoto e composti organici volatili in presenza di
                  luce solare. I livelli di ozono diventano tipicamente elevati
                  durante il periodo estivo.
                </p>
                <p class="mt-40">
                  Anche il metano (CH₄) persistendo a lungo in atmosfera
                  contribuisce alla generazione di O₃ di cui è uno dei
                  precursori
                </p>
                <div class="inquinanti-fonti mt-40">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.arpa.piemonte.it/approfondimenti/glossario/ozono"
                        title="Questo link si apre in una nuova scheda"
                        >ARPA - Glossario - Ozono
                        <i
                          class="fas fa-external-link-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card elevation="10" class="card-limit mt-40">
            <v-card-text>
              <v-simple-table v-if="!isMobile()" class="limit-table">
                <thead>
                  <tr>
                    <td colspan="4">
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.o3Icon
                        </v-icon>
                        Ozono
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite giornaliero</strong>
                    </td>
                    <td>Inferiore a</td>
                    <td><span class="green--text">180/h</span></td>
                    <td>microgrammi/m3</td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calYearly</v-icon>
                      <strong>Limite annuale</strong>
                    </td>
                    <td>Concentrazione media annua entro i</td>
                    <td>
                      <span class="green--text">120</span>
                    </td>
                    <td>microgrammi/m3</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table v-if="isMobile()" class="limit-table mt-40">
                <thead>
                  <tr>
                    <td>
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.o3Icon
                        </v-icon>
                        Ozono
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite giornaliero</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Inferiore a
                      <span class="green--text">180/h</span> microgrammi/m3
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calYearly</v-icon>
                      <strong>Limite annuale</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Concentrazione media annua entro i
                      <span class="green--text">120</span> microgrammi/m3
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card class="card-cont-img-behind-left zolfo" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h3>SO<small>2</small> Biossido di Zolfo</h3>
                <p class="mt-40">
                  L'anidride solforica è un gas pungente e incolore emesso da
                  centrali elettriche, industrie, navi e abitazioni. Una delle
                  principali cause delle piogge acide.
                </p>
                <div class="inquinanti-fonti mt-40">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.arpa.piemonte.it/approfondimenti/temi-ambientali/aria/aria/cartella-qualita-inquinanti/pagina-qualita-inquinanti#Biossido%20di%20zolfo"
                        title="Questo link si apre in una nuova scheda"
                        >ARPA - Inquinanti - Biossido di zolfo
                        <i
                          class="fas fa-external-link-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.arpa.piemonte.it/approfondimenti/glossario/biossido-di-zolfo"
                        title="Questo link si apre in una nuova scheda"
                        >ARPA - Glossario - Biossido di zolfo
                        <i
                          class="fas fa-external-link-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card elevation="10" class="card-limit mt-40">
            <v-card-text>
              <v-simple-table v-if="!isMobile()" class="limit-table">
                <thead>
                  <tr>
                    <td colspan="4">
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.soIcon
                        </v-icon>
                        Biossido di Zolfo
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite orario</strong>
                    </td>
                    <td>da non superare più di 24 ore l’anno</td>
                    <td><span class="green--text">350</span></td>
                    <td>microgrammi/m3</td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite giornaliero</strong>
                    </td>
                    <td>da non superare più di 3 giorni l’anno</td>
                    <td>
                      <span class="green--text">125</span>
                    </td>
                    <td>microgrammi/m3</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table v-if="isMobile()" class="limit-table mt-40">
                <thead>
                  <tr>
                    <td>
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.soIcon
                        </v-icon>
                        Biossido di Zolfo
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite orario</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="green--text">350</span> microgrammi/m3, da
                      non superare più di 24 ore l’anno
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite giornaliero</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="green--text">125</span> microgrammi/m3, da
                      non superare più di 3 giorni l’anno
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card class="card-cont-img-behind-right ammoniaca" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h3>NH<small>3</small> Ammoniaca</h3>
                <p class="mt-40">
                  Gas incolore, caustico e irritante con odore pungente. Viene
                  emesso dai reflui zootecnici da allevamento e dall'uso di
                  fertilizzanti in agricoltura.
                  <br />
                  Altamente solubile in acqua. È alcalino e reagisce con gli
                  acidi per formare sali di ammonio.
                  <br />
                  È uno dei precursori del PM secondario.
                </p>
                <p class="mt-40">
                  Non ci sono soglie in base alla normativa vigente.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card class="card-cont-img-behind-left cov" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h3>COV Composti Organici Volatili</h3>
                <p class="mt-40">
                  È una classe di composti chimici; in condizioni normali sono
                  gassosi o possono vaporizzare ed entrare nell'atmosfera.
                  Escluso il Metano, sono emessi dall'uso di solventi in
                  prodotti e industrie, veicoli stradali, riscaldamento
                  domestico e produzione di energia.
                </p>
                <p class="mt-40">
                  Alcuni sono di origine naturale. <br />
                  Unico COV normato in <strong>aria ambiente</strong> è il
                  Benzene.
                </p>
                <p class="mt-40 caption">
                  NOTA: “aria ambiente” significa che le misure devono avvenire
                  all’aperto, dove vivono le persone e fuori dai luoghi di
                  lavoro.
                </p>
                <div class="inquinanti-fonti mt-40">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.arpa.piemonte.it/approfondimenti/glossario/voc-composti-organici-volatili"
                        title="Questo link si apre in una nuova scheda"
                        >ARPA - Glossario - Composti organici volatili
                        <i
                          class="fas fa-external-link-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card elevation="10" class="card-limit mt-40">
            <v-card-text>
              <v-simple-table v-if="!isMobile()" class="limit-table">
                <thead>
                  <tr>
                    <td colspan="4">
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.covIcon
                        </v-icon>
                        Composti Organici Volatili
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calYearly</v-icon>
                      <strong>Limite annuo</strong>
                    </td>
                    <td>per il solo Benzene</td>
                    <td>
                      <span class="green--text">5</span>
                    </td>
                    <td>microgrammi/m3</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table v-if="isMobile()" class="limit-table mt-40">
                <thead>
                  <tr>
                    <td>
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.covIcon
                        </v-icon>
                        Composti Organici Volatili
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calYearly</v-icon>
                      <strong>Limite annuo</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      per il solo Benzene
                      <span class="green--text">5</span> microgrammi/m3
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card class="card-cont-img-behind-right monossido" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h3>CO Monossido di Carbonio</h3>
                <p class="mt-40">
                  Gas incolore, inodore e insapore, leggermente meno denso
                  dell'aria. In concentrazioni elevate risulta altamente tossico
                  per l’uomo e gli animali, poiché è in grado di fissare in modo
                  permanente l’emoglobina e contrastare il trasporto
                  dell'ossigeno all'interno dell'organismo. Viene generato da
                  qualsiasi combustione.
                </p>
                <div class="inquinanti-fonti mt-40">
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="http://www.arpa.piemonte.it/approfondimenti/glossario/monossido-di-carbonio"
                        title="Questo link si apre in una nuova scheda"
                        >ARPA - Glossario - Monossido di carbonio
                        <i
                          class="fas fa-external-link-alt"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card elevation="10" class="card-limit mt-40">
            <v-card-text>
              <v-simple-table v-if="!isMobile()" class="limit-table">
                <thead>
                  <tr>
                    <td colspan="4">
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.coIcon
                        </v-icon>
                        Monossido di Carbonio
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite giornaliero</strong>
                    </td>
                    <td>massima media di 8 ore nel giorno</td>
                    <td>
                      <span class="green--text">10</span>
                    </td>
                    <td>microgrammi/m3</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table v-if="isMobile()" class="limit-table mt-40">
                <thead>
                  <tr>
                    <td>
                      <h4>
                        <v-icon class="mr-20" large>
                          $vuetify.icons.coIcon
                        </v-icon>
                        Monossido di Carbonio
                      </h4>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-icon class="mr-20">$vuetify.icons.calDaily</v-icon>
                      <strong>Limite giornaliero</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="green--text">10</span> microgrammi/m3, come
                      massima media di 8 ore nel giorno
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
          <v-card class="card-cont-img-behind-left metano" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <h3>CH<small>4</small> Metano</h3>
                <p class="mt-40">
                  È un inquinante atmosferico e un gas serra. Attraverso una
                  serie di reazioni chimiche il metano contribuisce alla
                  formazione di Ozono (O₃). <br />
                  Le fonti più importanti sono il settore energetico, le
                  discariche, l'agricoltura e l’allevamento.
                </p>
                <p class="mt-40">
                  Non ci sono soglie in base alla normativa vigente
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuQualitaAria page="inquinanti" />
        </v-col>
      </v-row>
    </v-container>
    <div class="inquinanti">
      <v-container>
        <v-row justify-center>
          <v-col cols="8" offset="2">
            <p>
              Ci sono altri inquinanti atmosferici che possono causare gravi
              danni alla salute umana e all'ambiente. Tra questi: i
              <strong>metalli pesanti</strong> (come Mercurio, Arsenico, Piombo,
              Cadmio e Nichel) e gli
              <strong>Idrocarburi Policiclici Aromatici</strong> (come il
              Benzo(a)pirene).<br />
              La legislazione esistente ha già contribuito a ridurne
              l’emissione, con una conseguente diminuzione del rischio per la
              salute.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuQualitaAria from "@/views/qualita-aria/MenuQualitaAria";
import { mixinDetictingMobile } from "../../config/mixinDetictingMobile";

export default {
  components: {
    Header,
    MenuQualitaAria
  },
  data() {
    return {
      tab: null
    };
  },
  mixins: [mixinDetictingMobile]
};
</script>
